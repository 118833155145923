#creatorspage {
  background-color: #D2BA6A;
}

.wave .shape-fill-creatorpage {
  fill: #96864C;
}

.custom-shape-divider-top-1667335860 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.text {
  padding: 2vh 0vh 2vh 0vh;
  margin: 0;
  font-family: Gill Sans MT, Calibri, sans-serif;
}

.text-headline {
  padding: 0;
  margin: 0;
  font-family: Gill Sans MT, Calibri, sans-serif;
  font-size: 16px;
  font-size: 4vw;
}


.custom-shape-divider-top-1667335860 svg {
  position: relative;
  display: block;
  width: calc(199% + 1.3px);
  height: 191px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1667335860 .shape-fill {
  fill: #FFFFFF;
}