html {
    scroll-behavior: smooth;
}

#firstpart {
    background-color: black;
    min-height: 60vh;
    padding: 0px;
    position: relative;
    color: #FFB6C1;
    padding-top: 5%;
}

#secondpart {
    background-color: greenyellow;
    min-height: 60vh;
    padding: 0px;
    position: relative;
    color: black;
    padding-top: 0;

}

#thirdpart {
    background-color: black;
    min-height: 40vh;
    padding: 0px;
    color: #FFB6C1;
    padding-top: 0;
}

.podcastframe {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    background-color: #DDDDD1;
}

.textdiv {
    padding: 0 0 5vh 0;
}

.centerBox {
    display: flex;
    justify-content: center
}

.undrawsvg {
    width: 400px;
    max-width: 70vw
}

.textdiv .blue {
    background-color: #b6deff;
}

.text {
    padding: 2vh 0vh 2vh 0vh;
    margin: 0;
    font-family: Gill Sans MT, Calibri, sans-serif;
}

.text-headline {
    padding: 0;
    margin: 0;
    font-family: Gill Sans MT, Calibri, sans-serif;
    font-size: 16px;
    font-size: 4vw;
}

.wave {
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.wave svg {
    position: relative;
    display: block;
    width: calc(102% + 1.3px);
    height: 245px;
}

@media screen and (max-width: 500px) {
    .wave svg {
        height: 105px;
    }
}

.wave .shape-fill-pink {
    fill: #FFB6C1;
}

.wave .shape-fill-black {
    fill: #96864C;
}

.wave .shape-fill-yellow {
    fill: #DDDDD1;
}